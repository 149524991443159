import request from '@/router/axios';
/*import { warehouseApiBase, pvhApiBase } from "@/config/env"*/
import { pvhApiBase } from "@/config/env"
var warehouseApiBase = "http://localhost:5202/w/api/";
export const GetWarehouseTypes = (search,status,page, pageSize) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetWarehouseTypes",
        method: "get",
        params: {
            "searchText": search,
            "status" : status,
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const GetWarehouseType = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetWarehouseType",
        method: "get",
        params: data
    })
}

export const PostWarehouseType = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/PostWarehouseType",
        method: "post",
        data: {
             ...data
        } 
    })
}

export const PutWarehouseType = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/PutWarehouseType ",
        method: "put",
        data: data
    })
}

export const DeleteWarehouseType = (id) => {
    return request({
        url: warehouseApiBase + "Warehouse/DeleteWarehouseType",
        method: "delete",
        params: {
            id
        }
    })
}

export const GetWarehousePositions = (search, positionType, warehouseCode, page, pageSize) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetWarehousePositions",
        method: "get",
        params: {
            "searchText": search,
            "positionType": positionType,
            "warehouseCode": warehouseCode,
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const GetWarehousePosition = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetWarehousePosition",
        method: "get",
        params: data
    })
}

export const PostWarehousePosition = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/PostWarehousePosition",
        method: "post",
        data: {
            ...data
        }
    })
}

export const PutWarehousePosition = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/PutWarehousePosition ",
        method: "put",
        data: data
    })
}

export const DeleteWarehousePosition = (id) => {
    return request({
        url: warehouseApiBase + "Warehouse/DeleteWarehousePosition",
        method: "delete",
        params: {
            id
        }
    })
}

export const GetWarehouses = (search, disable, warehouseType, page, pageSize) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetWarehouses",
        method: "get",
        params: {
            "searchText": search,
            "disable": disable,
            "warehouseType": warehouseType,
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const GetWarehouse = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetWarehouse",
        method: "get",
        params: data
    })
}

export const PostWarehouse = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/PostWarehouse",
        method: "post",
        data: {
            ...data
        }
    })
}

export const PutWarehouse = (data) => {
    return request({
        url: warehouseApiBase + "Warehouse/PutWarehouseInfo ",
        method: "put",
        data: data
    })
}

export const DeleteWarehouseInfo = (id) => {
    return request({
        url: warehouseApiBase + "Warehouse/DeleteWarehouseInfo",
        method: "delete",
        params: {
            id
        }
    })
}

export const GetListWarehouseInfos = () => {
    return request({
        url: warehouseApiBase + "Warehouse/GetListWarehouseInfos",
        method: "get"
    })
}

export const GetListWarehouseTypes = () => {
    return request({
        url: warehouseApiBase + "Warehouse/GetListWarehouseTypes",
        method: "get"
    })
}

export const GetListWarehousePositionsByWarehouseCode = (warehouseCode) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetListWarehousePositionsByWarehouseCode",
        method: "get",
        params: {
            "warehouseCode": warehouseCode
        }
    })
}

export const GetParentListWarehousePositionsByWarehouseCode = (warehouseCode,positionCode) => {
    return request({
        url: warehouseApiBase + "Warehouse/GetParentListWarehousePositionsByWarehouseCode",
        method: "get",
        params: {
            "warehouseCode": warehouseCode,
            "positionCode": positionCode
        }
    })
}

export const GetCountryAddress = () => {
    return request({
        url: pvhApiBase + "Tracing/GetCountryAddress",
        method: "get"
    })
}

export const GetCityAddressByCountry = (country) => {
    return request({
        url: pvhApiBase + "Tracing/GetCityAddressByCountry",
        method: "get",
        params: {
            "country": country
        }
    })
}