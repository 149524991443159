var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehouseInfo" },
    [
      _c("div", { staticClass: "pvhFactory" }, [
        _c(
          "div",
          {},
          [
            _c("div", { staticClass: "layTop d-flex" }, [
              _c(
                "div",
                {
                  staticClass: "Title",
                  staticStyle: { "margin-bottom": "10px" }
                },
                [_vm._v(_vm._s(_vm.$t("Warehouse Info")))]
              )
            ]),
            _c(
              "el-row",
              { staticClass: "topFromSearch", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { height: "auto" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                      },
                      [
                        _c(
                          "span",
                          {
                            style:
                              _vm.codeVal == ""
                                ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                : "top: -10px;left: 20px;transform: translate(0, 0);"
                          },
                          [_vm._v(_vm._s(_vm.$t("KEYWORDS")) + ":")]
                        ),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.codeVal,
                            callback: function($$v) {
                              _vm.codeVal = $$v
                            },
                            expression: "codeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c("el-col", {
                      staticClass: "formDiv",
                      staticStyle: { display: "none" },
                      attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                    }),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                      },
                      [
                        _c("span", { attrs: { id: "statusSpan" } }, [
                          _vm._v(_vm._s(_vm.$t("Status")) + ":")
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { "collapse-tags": "", placeholder: "" },
                            on: {
                              focus: function($event) {
                                return _vm.focusDiv($event)
                              },
                              blur: function($event) {
                                return _vm.blurDiv($event)
                              }
                            },
                            model: {
                              value: _vm.statusVal,
                              callback: function($$v) {
                                _vm.statusVal = $$v
                              },
                              expression: "statusVal"
                            }
                          },
                          _vm._l(this.statusData, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                      },
                      [
                        _c("span", { attrs: { id: "warehouseTypeSpan" } }, [
                          _vm._v(_vm._s(_vm.$t("Warehouse Type")) + ":")
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: ""
                            },
                            on: {
                              focus: function($event) {
                                return _vm.focusDiv($event)
                              },
                              blur: function($event) {
                                return _vm.blurDiv($event)
                              }
                            },
                            model: {
                              value: _vm.warehouseTypeVal,
                              callback: function($$v) {
                                _vm.warehouseTypeVal = $$v
                              },
                              expression: "warehouseTypeVal"
                            }
                          },
                          _vm._l(_vm.GetCurrWarehouseTypeData, function(item) {
                            return _c("el-option", {
                              key: item.code,
                              attrs: { label: item.name, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "topFromSearch", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "align-self": "flex-end"
                    },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          title: _vm.$t("Search"),
                          icon: "iconfont sousuo",
                          size: "mini",
                          type: "primary"
                        },
                        on: { click: _vm.search }
                      },
                      [_vm._v("  " + _vm._s(_vm.$t("Search")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "iconfont tianjia",
                          size: "mini",
                          title: _vm.$t("New"),
                          plain: ""
                        },
                        on: { click: _vm.addBtn }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("New")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", {
              staticStyle: {
                "padding-top": "10px",
                "text-align": "right",
                "align-self": "flex-end"
              }
            }),
            _c(
              "div",
              { staticStyle: { "margin-top": "10px" } },
              [
                _c(
                  "avue-crud",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "pvhTable",
                    attrs: {
                      option: _vm.tableOpt,
                      data: _vm.tableData,
                      "row-style": _vm.rowStyle,
                      page: _vm.page
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "current-change": _vm.currentChange
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "menu",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          var size = ref.size
                          var type = ref.type
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.viewBtn(row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("View")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Edit")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.delBtn(row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Delete")))]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.obj,
                      callback: function($$v) {
                        _vm.obj = $$v
                      },
                      expression: "obj"
                    }
                  },
                  [
                    _c(
                      "template",
                      {
                        staticStyle: { width: "calc(100% - 80px)" },
                        slot: "menuRight"
                      },
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "topFromSearch",
                            attrs: { gutter: 20 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "padding-top": "10px" },
                                attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      icon: "el-icon-plus"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                  " +
                                        _vm._s(_vm.$t("ADD")) +
                                        "\n                              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Warehouse"),
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          staticStyle: { display: "none" },
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Id"),
                                prop: "id",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: { maxlength: "64" },
                                model: {
                                  value: _vm.ruleForm.Id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "Id", $$v)
                                  },
                                  expression: "ruleForm.Id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Warehouse Name"),
                                prop: "warehouseName",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.warehouseName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "warehouseName", $$v)
                                  },
                                  expression: "ruleForm.warehouseName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Warehouse Code"),
                                prop: "warehouseCode",
                                "label-width": "120px",
                                rules: _vm.rules.warehouseCode,
                                disabled: _vm.ruleForm.id
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.warehouseCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "warehouseCode", $$v)
                                  },
                                  expression: "ruleForm.warehouseCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("Warehouse Type"),
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Warehouse Type"),
                                    prop: "warehouseType",
                                    "label-width": "120px",
                                    rules: _vm.rules.warehouseType
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "",
                                        disabled: _vm.viewdetail
                                      },
                                      model: {
                                        value: _vm.ruleForm.warehouseType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "warehouseType",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.warehouseType"
                                      }
                                    },
                                    _vm._l(
                                      this.ruleForm.warehouseTypeData,
                                      function(item) {
                                        return _c("el-option", {
                                          key: item.key,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("Country"),
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("Country"),
                                    prop: "country",
                                    "label-width": "120px"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "",
                                        disabled: _vm.viewdetail
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.countrychange(
                                            _vm.ruleForm.country
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.ruleForm.country,
                                        callback: function($$v) {
                                          _vm.$set(_vm.ruleForm, "country", $$v)
                                        },
                                        expression: "ruleForm.country"
                                      }
                                    },
                                    _vm._l(this.ruleForm.countryData, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("City"),
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("City"),
                                    prop: "city",
                                    "label-width": "120px"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "",
                                        disabled: _vm.viewdetail
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.citychange(
                                            _vm.ruleForm.city
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.ruleForm.city,
                                        callback: function($$v) {
                                          _vm.$set(_vm.ruleForm, "city", $$v)
                                        },
                                        expression: "ruleForm.city"
                                      }
                                    },
                                    _vm._l(this.ruleForm.cityData, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("ADDRESS"),
                                prop: "address",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "address", $$v)
                                  },
                                  expression: "ruleForm.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "el-row_style",
                      staticStyle: { display: "none" }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("LATITUDE"),
                                prop: "lat",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: { maxlength: "64", readonly: true },
                                model: {
                                  value: _vm.ruleForm.lat,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "lat", $$v)
                                  },
                                  expression: "ruleForm.lat"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("LONGITUDE"),
                                prop: "lng",
                                "label-width": "140px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: { maxlength: "64", readonly: true },
                                model: {
                                  value: _vm.ruleForm.lng,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "lng", $$v)
                                  },
                                  expression: "ruleForm.lng"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("CONTACTS"),
                                prop: "contact",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.contact,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "contact", $$v)
                                  },
                                  expression: "ruleForm.contact"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Mobile"),
                                prop: "mobile",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.mobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "mobile", $$v)
                                  },
                                  expression: "ruleForm.mobile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("EMAIL"),
                                prop: "email",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "email", $$v)
                                  },
                                  expression: "ruleForm.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Available or no"),
                                prop: "disabled",
                                "label-width": "140px"
                              }
                            },
                            [
                              _c("el-switch", {
                                attrs: { disabled: _vm.viewdetail },
                                model: {
                                  value: _vm.ruleForm.disabled,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "disabled", $$v)
                                  },
                                  expression: "ruleForm.disabled"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.$t("ParentWarehouse"),
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("ParentWarehouse"),
                                    prop: "parentWarehouse",
                                    "label-width": "120px"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "",
                                        disabled: _vm.viewdetail
                                      },
                                      model: {
                                        value: _vm.ruleForm.parentWarehouse,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "parentWarehouse",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.parentWarehouse"
                                      }
                                    },
                                    _vm._l(
                                      this.ruleForm.parentWarehouseData,
                                      function(item) {
                                        return _c("el-option", {
                                          key: item.key,
                                          attrs: {
                                            label: item.name,
                                            value: item.code
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("DefaultInTransitWarehouse"),
                                prop: "defaultInTransitWarehouse",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.defaultInTransitWarehouse,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "defaultInTransitWarehouse",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.defaultInTransitWarehouse"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Humidity"),
                                prop: "humidity",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.humidity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "humidity", $$v)
                                  },
                                  expression: "ruleForm.humidity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("Temperature"),
                                prop: "temperature",
                                "label-width": "140px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.temperature,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "temperature", $$v)
                                  },
                                  expression: "ruleForm.temperature"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "el-row_style" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "el-col_style",
                          attrs: { span: 12, sm: 24, md: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("DESCRIPTION"),
                                prop: "description",
                                "label-width": "120px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 115px)" },
                                attrs: {
                                  maxlength: "64",
                                  disabled: _vm.viewdetail
                                },
                                model: {
                                  value: _vm.ruleForm.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "description", $$v)
                                  },
                                  expression: "ruleForm.description"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      !_vm.viewdetail
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.submitForm("ruleForm")
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }