<template>
  <div class="warehouseInfo">
    <div class="pvhFactory">
        <div class="">
            <div class="layTop d-flex">
                <div class="Title" style="margin-bottom: 10px;">{{ $t('Warehouse Info') }}</div>

            </div>
            <el-row :gutter="20" class="topFromSearch">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span :style="codeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('KEYWORDS')}}:</span>
                        <el-input @focus="focusDiv($event)" @blur="blurDiv($event)" v-model="codeVal"></el-input>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4" style="display:none">
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span id="statusSpan" :style="">{{$t('Status')}}:</span>
                        <el-select v-model="statusVal" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;">
                            <el-option v-for="item in this.statusData"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span id="warehouseTypeSpan" :style="">{{$t('Warehouse Type')}}:</span>
                        <el-select v-model="warehouseTypeVal" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;">
                            <el-option v-for="item in GetCurrWarehouseTypeData"
                                       :key="item.code"
                                       :label="item.name"
                                       :value="item.code">
                            </el-option>
                        </el-select>
                    </el-col>

                </el-col>

            </el-row>
            <el-row :gutter="20" class="topFromSearch">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: right; align-self: flex-end;">
                    <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="search">  {{$t('Search')}}</el-button>
                    <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="addBtn"> {{$t('New')}}</el-button>
                </el-col>
            </el-row>
            <div style="padding-top: 10px; text-align: right; align-self: flex-end;">
            </div>
            <div style="margin-top: 10px;">
                <avue-crud class="pvhTable"
                           v-loading="loading"
                           v-model="obj"
                           :option="tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @current-change="currentChange">

                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">

                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px;">
                                <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;">
                                    {{ $t('ADD') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="small" @click="viewBtn(row)">{{ $t('View') }}</el-button>
                        <el-button type="text" size="small" @click="editBtn(row)">{{ $t('Edit') }}</el-button>
                        <el-button type="text" size="small" @click="delBtn(row)">{{ $t('Delete') }}</el-button>
                    </template>
                </avue-crud>
            </div>
        </div>
    </div>

    <el-dialog
        :title="$t('Warehouse')"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">
        <div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style" style="display:none">
                        <el-form-item :label="$t('Id')" prop="id" label-width="120px">
                            <el-input v-model="ruleForm.Id" style="width: calc(100% - 115px);" maxlength="64"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('Warehouse Name')" prop="warehouseName" label-width="120px">
                            <el-input v-model="ruleForm.warehouseName" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('Warehouse Code')" prop="warehouseCode" label-width="120px" :rules="rules.warehouseCode" :disabled="ruleForm.id">
                            <el-input v-model="ruleForm.warehouseCode" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-tooltip class="item" effect="dark" :content="$t('Warehouse Type')" placement="top-start">
                            <el-form-item :label="$t('Warehouse Type')" prop="warehouseType" label-width="120px" :rules="rules.warehouseType">
                                <el-select v-model="ruleForm.warehouseType" filterable style="width: calc(100% - 115px);" clearable placeholder="" :disabled="viewdetail">
                                    <el-option v-for="item in this.ruleForm.warehouseTypeData"
                                               :key="item.key"
                                               :label="item.name"
                                               :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-tooltip class="item" effect="dark" :content="$t('Country')" placement="top-start">
                            <el-form-item :label="$t('Country')" prop="country" label-width="120px">
                                <el-select v-model="ruleForm.country" filterable style="width: calc(100% - 115px);" @change="countrychange(ruleForm.country)" clearable placeholder="" :disabled="viewdetail">
                                    <el-option v-for="item in this.ruleForm.countryData"
                                               :key="item.id"
                                               :label="item.name"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-tooltip>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-tooltip class="item" effect="dark" :content="$t('City')" placement="top-start">
                            <el-form-item :label="$t('City')" prop="city" label-width="120px">
                                <el-select v-model="ruleForm.city" filterable style="width: calc(100% - 115px);" @change="citychange(ruleForm.city)" clearable placeholder="" :disabled="viewdetail">
                                    <el-option v-for="item in this.ruleForm.cityData"
                                               :key="item.id"
                                               :label="item.name"
                                               :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('ADDRESS')" prop="address" label-width="120px">
                            <el-input v-model="ruleForm.address" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style" style="display:none">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('LATITUDE')" prop="lat" label-width="120px">
                            <el-input v-model="ruleForm.lat" style="width: calc(100% - 115px);" maxlength="64" :readonly="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('LONGITUDE')" prop="lng" label-width="140px">
                            <el-input v-model="ruleForm.lng" style="width: calc(100% - 115px);" maxlength="64" :readonly="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('CONTACTS')" prop="contact" label-width="120px">
                            <el-input v-model="ruleForm.contact" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('Mobile')" prop="mobile" label-width="120px">
                            <el-input v-model="ruleForm.mobile" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('EMAIL')" prop="email" label-width="120px">
                            <el-input v-model="ruleForm.email" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('Available or no')" prop="disabled" label-width="140px">
                            <el-switch v-model="ruleForm.disabled" :disabled="viewdetail"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-tooltip class="item" effect="dark" :content="$t('ParentWarehouse')" placement="top-start">
                            <el-form-item :label="$t('ParentWarehouse')" prop="parentWarehouse" label-width="120px">
                                <el-select v-model="ruleForm.parentWarehouse" filterable style="width: calc(100% - 115px);" clearable placeholder="" :disabled="viewdetail">
                                    <el-option v-for="item in this.ruleForm.parentWarehouseData"
                                               :key="item.key"
                                               :label="item.name"
                                               :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('DefaultInTransitWarehouse')" prop="defaultInTransitWarehouse" label-width="120px">
                            <el-input v-model="ruleForm.defaultInTransitWarehouse" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('Humidity')" prop="humidity" label-width="120px">
                            <el-input v-model="ruleForm.humidity" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('Temperature')" prop="temperature" label-width="140px">
                            <el-input v-model="ruleForm.temperature" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="el-row_style">
                    <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                        <el-form-item :label="$t('DESCRIPTION')" prop="description" label-width="120px">
                            <el-input v-model="ruleForm.description" style="width: calc(100% - 115px);" maxlength="64" :disabled="viewdetail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" v-if="!viewdetail">OK</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>

  </div>
</template>

<script>
import {
        GetListWarehouseTypes,
        GetWarehouses,
        GetWarehouse,
        PostWarehouse,
        PutWarehouse,
        DeleteWarehouseInfo,
        GetListWarehouseInfos,
        GetCountryAddress,
        GetCityAddressByCountry
    } from '@/api/warehouse'

export default {
  name: "warehouseInfo",
  data() {
    return {
        loading: false,
        viewdetail: false,
        GetCurrWarehouseTypeData:[],
      ruleForm: {
          id: false,          
          description: '',
          warehouseCode: '',
          warehouseName: '',
          warehouseType: '',
          parentWarehouse: '',
          defaultInTransitWarehouse: '',
          country: '',
          city: '',
          address: '',
          contact: '',
          mobile: '',
          email: '',
          humidity: '',
          temperature: '',
          lat: '',
          lng: '',
          disabled: 0,
          warehouseTypeData: [],
          countryData: [],
          cityData: [],
          parentWarehouseData:[]
      },
      rules: {
          warehouseCode: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
          warehouseName: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
      },
        dialogVisible: false,
        codeVal: '',
        statusVal: '',
        warehouseTypeVal: '',
      YesNoData: [
        {
          label: this.$t('Disable'),
          value: 1
        }, {
          label: this.$t('Available'),
          value: 0
        }

      ],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableData: [],
        obj: {},
        statusData: [
            {
                label: this.$t('NORMAL'),
                value: 0
            },
            {
                label: this.$t('DISABLE'),
                value: 1
            }
        ],
    }
  },
  created() {
    this.list();
      GetListWarehouseTypes().then((response) => {
          this.ruleForm.warehouseTypeData = response.data;
          this.GetCurrWarehouseTypeData = response.data;
      });
      GetListWarehouseInfos().then((response) => {
          this.parentWarehouseData = response.data;
      })
  },
  methods: {
    submitForm(formName) {
      var ruleForm = this.ruleForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
            if (ruleForm.id) {
              PutWarehouse({
                  description: ruleForm.description,
                  warehouseCode: ruleForm.warehouseCode,
                  warehouseName: ruleForm.warehouseName,
                  warehouseType: ruleForm.warehouseType,
                  parentWarehouse: ruleForm.parentWarehouse,
                  defaultInTransitWarehouse: ruleForm.defaultInTransitWarehouse,
                  country: ruleForm.country,
                  city: ruleForm.city,
                  address: ruleForm.address,
                  contact: ruleForm.contact,
                  mobile: ruleForm.mobile,
                  email: ruleForm.email,
                  humidity: ruleForm.humidity,
                  temperature: ruleForm.temperature,
                  lat: ruleForm.lat,
                  lng: ruleForm.lng,
                  disabled: ruleForm.disabled == true ? 0 : 1,
                  id: ruleForm.Id
            }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          } else {
              PostWarehouse({
                  description: ruleForm.description,
                  warehouseCode: ruleForm.warehouseCode,
                  warehouseName: ruleForm.warehouseName,
                  warehouseType: ruleForm.warehouseType,
                  parentWarehouse: ruleForm.parentWarehouse,
                  defaultInTransitWarehouse: ruleForm.defaultInTransitWarehouse,
                  country: ruleForm.country,
                  city: ruleForm.city,
                  address: ruleForm.address,
                  contact: ruleForm.contact,
                  mobile: ruleForm.mobile,
                  email: ruleForm.email,
                  humidity: ruleForm.humidity,
                  temperature: ruleForm.temperature,
                  lat: ruleForm.lat,
                  lng: ruleForm.lng,
                  disabled: ruleForm.disabled == true ? 0 : 1
            }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
        this.ruleForm = {
            description: '',
            warehouseCode: '',
            warehouseName: '',
            warehouseType: '',
            parentWarehouse: '',
            defaultInTransitWarehouse: '',
            country: '',
            city: '',
            address: '',
            contact: '',
            mobile: '',
            email: '',
            humidity: '',
            temperature: '',
            lat: '',
            lng: '',
            disabled: 0,
            id:false
        };
        this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    search() {
        this.list();
    },
    delBtn(row) {
        DeleteWarehouseInfo(row.id).then((response) => {
            if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({ message: this.$t('OperationSuccess'), type: "success" });
                this.page.total = 0;
                this.list();
            } else {
                this.$message({ message: this.$t('OperationFailed'), type: "warning" });
            }
      })
    },
    editBtn(row) {
      var ruleForm = this.ruleForm;
      this.dialogVisible = true;
        this.ruleForm.id = true;
        this.viewdetail = false;
        ruleForm.warehouseName = row.warehouseName;
        ruleForm.warehouseCode = row.warehouseCode;
        ruleForm.warehouseType = row.warehouseType;
        ruleForm.parentWarehouse = row.parentWarehouse;
        ruleForm.disabled = row.disabled != 1 ? true : false;

        ruleForm.description = row.description;
        ruleForm.defaultInTransitWarehouse = row.defaultInTransitWarehouse;
        ruleForm.country = row.country;
        ruleForm.city = row.city;
        ruleForm.address = row.address;
        ruleForm.contact = row.contact;
        ruleForm.mobile = row.mobile;
        ruleForm.email = row.email;
        ruleForm.humidity = row.humidity;
        ruleForm.temperature = row.temperature;
        ruleForm.lat = row.lat;
        ruleForm.lng = row.lng;
        ruleForm.Id = row.id;
        GetCountryAddress().then((response) => {
            this.ruleForm.countryData = response.data;
        })
      },
      viewBtn(row) {
          var ruleForm = this.ruleForm;
          this.dialogVisible = true;
          this.ruleForm.id = true;
          this.viewdetail = true;
          ruleForm.warehouseName = row.warehouseName;
          ruleForm.warehouseCode = row.warehouseCode;
          ruleForm.warehouseType = row.warehouseType;
          ruleForm.parentWarehouse = row.parentWarehouse;
          ruleForm.disabled = row.disabled != 1 ? true : false;

          ruleForm.description = row.description;
          ruleForm.defaultInTransitWarehouse = row.defaultInTransitWarehouse;
          ruleForm.country = row.country;
          ruleForm.city = row.city;
          ruleForm.address = row.address;
          ruleForm.contact = row.contact;
          ruleForm.mobile = row.mobile;
          ruleForm.email = row.email;
          ruleForm.humidity = row.humidity;
          ruleForm.temperature = row.temperature;
          ruleForm.lat = row.lat;
          ruleForm.lng = row.lng;
          ruleForm.Id = row.id;
      },
    list() {
        this.loading = true;
        GetWarehouses(this.codeVal, this.statusVal, this.warehouseTypeVal, this.page.currentPage, this.page.pageSize).then((response) => {
        this.loading = false;
        this.tableData = response.data.warehouses;
        this.page.total = response.data.totalCount;
        this.page.pageSize = response.data.pageSize;
      })
    },
    addBtn() {
      this.dialogVisible = true;
      this.ruleForm.id = false;
      this.GetCurrWarehouseType();
    },
      GetCurrWarehouseType() {
          GetListWarehouseTypes().then((response) => {
              this.ruleForm.warehouseTypeData = response.data;
              this.GetCurrWarehouseTypeData = response.data;
          })
    },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
      },
      focusDiv(obj) {//�������ȡ����
          if (obj.currentTarget === undefined) {
              obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
          } else {
              if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                  obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
              } else {
                  obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
              }
          }
      },//������ʧȥ���� 
      blurDiv(obj) {
          if (obj.currentTarget === undefined) {
              if (obj.value === "") {
                  obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
              }
          } else if (obj.currentTarget === null) {
              if (this.fromVal.ids.length === 0) {
                  document.querySelector("#statusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
                  document.querySelector("#warehouseTypeSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
              }
          } else {
              if (obj.target.value == "") {
                  obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
              }
          }
      },
      countrychange(value) {
          GetCityAddressByCountry(value).then((response) => {
              this.ruleForm.cityData = response.data;
              this.ruleForm.city = '';
              this.ruleForm.lat = '';
              this.ruleForm.lng = '';
          })
      },
      citychange(value) {
          this.ruleForm.cityData.forEach((f) => {
              if (f.id == value) {
                  this.ruleForm.lat = f.lat;
                  this.ruleForm.lng = f.lng;
              }
          })
      },
    currentChange(val) {
        this.page.currentPage = val;
        this.list();
    },
      handleClose() {
      //this.resetForm('ruleForm');
      this.dialogVisible = false;
    }
  },
  computed: {
    tableOpt() {
      return {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: true,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
                label: this.$t('Warehouse Name'),
                prop: 'warehouseName',
          },
          {
              label: this.$t('Warehouse Code'),
              prop: 'warehouseCode',
          },
          {
              label: this.$t('Warehouse Type'),
              prop: 'warehouseType',
          },
          {
              label: this.$t('Country'),
              prop: 'country',
            },
            {
                label: this.$t('City'),
                prop: 'city',
            },
            {
                label: this.$t('ADDRESS'),
                prop: 'address',
            },
            {
                label: this.$t('STATE'),
                prop: 'disabled',
                placeholder: ' ',
                type: "select",
                dicData: this.statusData,
            }
        ]
      }
    },
  }
}
</script>

<style scoped lang="scss">
.warehouseInfo {
    padding: 15px;

    .pvhFactory {
        padding: 15px;
        border-radius: 8px;
        background: #FFFFFF;
    }
}

.layTop {
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
</style>
